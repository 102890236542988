import {
  type UserSignInData,
  postUserSignIn,
  type UserSignInResponse,
} from 'domains/api/postUserSignIn';

interface AuthProvider {
  isAuthenticated: boolean;
  username: null | string;
  signIn: (data: UserSignInData) => Promise<void>;
  signOut: () => void;
}

const STORAGE_TOKEN_KEY = 'token';
const STORAGE_USERNAME_KEY = 'username';

export const authProvider: AuthProvider = {
  isAuthenticated: !(
    sessionStorage.getItem(STORAGE_TOKEN_KEY) == null ||
    sessionStorage.getItem(STORAGE_USERNAME_KEY) == null
  ),
  username: sessionStorage.getItem(STORAGE_USERNAME_KEY),

  async signIn(data: UserSignInData) {
    const response = await postUserSignIn(data);

    if (response.ok) {
      await response.json().then((data: UserSignInResponse) => {
        authProvider.isAuthenticated = true;
        sessionStorage.setItem(STORAGE_TOKEN_KEY, data.user.token);
        sessionStorage.setItem(STORAGE_USERNAME_KEY, data.user.name);
      });
    }
  },

  signOut() {
    sessionStorage.removeItem(STORAGE_TOKEN_KEY);
    sessionStorage.removeItem(STORAGE_USERNAME_KEY);
    authProvider.isAuthenticated = false;
    authProvider.username = null;
  },
};
